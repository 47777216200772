import { useTheme } from '@emotion/react';
import StyledButton from '@components/Button';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import { isMobile } from 'react-device-detect';

const Container = styled.div(({ theme, hoverCursor }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    position: 'relative',
    width: '100%',
    transition: theme.transition.default,
    'button': {
        display: 'none'
    },
    '.btnContainer': {
        display: 'none'
    },
    '&:hover': {
        button: {
            display: 'inline-block'
        },
        '.btnContainer': {
            display: 'block'
        }
    },
    [theme.mediaQuery.largeMobileUp]: {
        margin: theme.spacing.xxs
    }
}));

const Image = styled.img(({ theme }) => ({
    paddingTop: theme.spacing.xxs,
    height: theme.size.assetWidthTiny,
    maxWidth: '100%',
    objectFit: 'contain',
    [theme.mediaQuery.largeMobileUp]: {
        height: theme.size.assetWidth
    }
}));

const Video = styled.video(({ theme }) => ({
    paddingTop: theme.spacing.xxs,
    height: theme.size.assetWidthTiny,
    maxWidth: '100%',
    objectFit: 'contain',
    [theme.mediaQuery.largeMobileUp]: {
        height: theme.size.assetWidth
    }
}));

const Badge = styled.span(({ theme }) => ({
    color: theme.colors.backgroundColor,
    backgroundColor: theme.colors.primary,
    borderRadius: theme.borderRadius.l,
    width: theme.size.mintWidth,
    height: theme.size.mintHeight,
    textAlign: 'center',
    lineHeight: theme.size.mintHeight
}));

const Caption = styled.p(({ theme }) => ({
    color: theme.colors.tertirary,
    marginTop: theme.spacing.xxs,
    zIndex: 10
}));

const DisclaimerContainer = styled.div(() => ({
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
}));

const Disclaimer = styled.div(({ theme }) => ({
    color: theme.colors.tertiary,
    backgroundColor: theme.colors.backgroundColor,
    padding: theme.spacing.xxs,
    borderRadius: theme.borderRadius.s,
    width: '80%',
    textAlign: 'center',
    margin: 'auto'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    bottom: theme.spacing.xs,
    right: 0,
    left: 0,
    width: isMobile ? '100%' : '',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

function GridItem({ id, mintNumber, srcImg, textColor, srcBackImg, badge, caption, onClick, buttons, templateId, secondary, type = 'image' }) {
    const theme = useTheme();

    const ButtonStyle = {
        marginBottom: theme.spacing.xs,
        width: theme.size.gridItemButtonsWidth,
        display: isMobile ? 'block' : '',
        marginLeft: isMobile ? 'auto' : '',
        marginRight: isMobile ? 'auto' : ''
    };

    const captionStyle = {
        ...theme.typography.h6,
        color: `${textColor}`,
        textTransform: 'uppercase',
        fontWeight: 'bold'
    };

    return (
        <Container onClick={() => onClick({ id, mintNumber, type, srcImg, srcBackImg, caption, badge, templateId })}>
            { badge ? <Badge>{badge}</Badge> : null }
            { badge && badge === '#?' ? <DisclaimerContainer><Disclaimer>{STRINGS.assetNotMintedDisclaimer}</Disclaimer></DisclaimerContainer> : null }
            { type === 'video' ?
                <Video muted autoPlay loop playsInline height={theme.size.assetWidth}>
                    <source src={srcImg} type="video/mp4" />
                </Video>
                : <Image alt={caption} src={srcImg} />
            }
            { buttons ?
                <>
                    <ButtonContainer>
                        { buttons.map((button, index) => (
                            <StyledButton
                                key={index}
                                style={ButtonStyle}
                                secondary={button.secondary}
                                onClick={(e) => {
                                    if (button.onClick) {
                                        e.stopPropagation();
                                        button.onClick({ id, mintNumber, type, srcImg, srcBackImg, caption, badge, templateId });
                                    }
                                }}
                            >
                                {button.label}
                            </StyledButton>
                        ))}
                    </ButtonContainer>
                </>
                : null }
            { caption ? <Caption style={captionStyle}>{caption}</Caption> : null }

        </Container>
    );
}

export default GridItem;
