import React, { useEffect, useState } from 'react';
import { useTheme, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import Container from '@components/Container';
import FormModal from '@components/FormModal';
import Button from '@components/Button';
import STRINGS from '@utils/strings';
import { state } from '../../globalState';
import { useSnapshot } from 'valtio';

const HeadlineText = styled.h1(({ theme }) => ({
    ...theme.typography.h2,
    margin: 0,
    maxWidth: '40ch'
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65ch'
}));

const scale = keyframes`
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
`;

const Image = styled.img(({ theme }) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center 12.5%',
    maxWidth: '70vw',
    animation: `${scale} 7s ease-in-out infinite alternate`
}));

const SlideContainer = styled.ul(({ theme }) => ({
    position: 'relative',
    padding: '0',
    listStyleType: 'none',
    width: '70%',
    height: '100%'
}));

const Slide = styled.li(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    right: '-2rem',
    overflow: 'hidden',
    opacity: '0',
    transition: 'all ease-in-out 320ms',
    '&.active': {
        opacity: '1',
        transform: 'translateX(-2rem)'
    }
}));

const Mask = styled.div(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    background: theme.colors.common.background === '#FFFFFF' ? '' : `linear-gradient(0deg, ${theme.colors.common.background} 0%, ${theme.colors.common.backgroundAlpha} 20%)`
}));

const Caption = styled.div(({ theme }) => ({
    position: 'absolute',
    color: '#FFFFFF',
    width: '100%',
    bottom: '0',
    left: '0',
    fontWeight: '800',
    fontSize: '1.25rem',
    letterSpacing: '0.04em',
    textAlign: 'right',
    padding: '1rem 2rem'
}));

const BackgroundColorContainer = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.grey.intensity900,
    color: theme.colors.grey.foreground,
    display: 'flex',
    justifyContent: 'center',
    [ theme.mediaQuery.tabletUp ]: {
        padding: `${theme.spacing.l}`
    }
}));

const Video = styled.video(({ theme }) => ({
    width: 260,
    objectFit: 'contain',
    margin: '1rem',
    [ theme.mediaQuery.largeMobileUp ]: {
        margin: '2rem'
    }
}));

function Headline() {
    const theme = useTheme();

    const snap = useSnapshot(state);

    const [ showNewsletter, setShowNewsletter ] = useState(false);

    const [ timer, setTimer ] = useState(0);
    const [ slidesRunning, setSlidesRunning ] = useState(false);

    const slideData = [
        {
            name: "Dark Pinup",
            image: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-dark_pinup-vixen.jpg",
            pack: "",
            url: ""
        },
        {
            name: "Finney Vol. 1",
            image: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-cryptowriter-finneys_arrival.jpg",
            pack: "",
            url: ""
        },
        {
            name: "NFTrippy",
            image: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-nftrippy-shrooman.jpg",
            pack: "",
            url: ""
        },
        {
            name: "The Strange & Unusual",
            image: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-strangeunusual-doggy.jpg",
            pack: "",
            url: ""
        }
    ];

    const playSlides = (elementId, slideDuration) => {

        // const start = Date.now();
        const el = document.getElementById(elementId);
        const children = el.children;
        let i = 0;

        const activateSlide = () => {
            if (i === children.length) {i = 0;}
            // const millis = Date.now() - start;
            // console.log('slide:', i, `seconds elapsed = ${Math.floor(millis / 1000)}`);

            const activeChild = document.getElementsByClassName('active');
            if (activeChild.length > 0) {activeChild[0].classList.remove('active');}

            children[i].classList.add('active');
            i += 1;

        };

        const t = setInterval(activateSlide, slideDuration);
        setTimer(t);
        setSlidesRunning(true);

        return activateSlide;

    };

    useEffect(() => {
        if (!slidesRunning) {
            const slideshowInstance = playSlides('slideshow', 7000);
            slideshowInstance();
        }

        return () => {
            if (slidesRunning) {
                clearInterval(timer);
                setTimer(0);
                setSlidesRunning(false);
                // console.log('interval reset ', timer, slidesRunning);
            }
        };
    }, [slidesRunning]);

    return (
        <BackgroundColorContainer>
            <Container
                position='relative'
                gap={theme.spacing.s}
                padding={`0 0 0 ${theme.spacing.xl}`}
                direction='row-reverse'
                justifyContent='space-between'
                isFullWidth={false}
            >
                <SlideContainer
                    id='slideshow'
                >
                    {
                        slideData.map(el => (<Slide key={el.name}>
                            <Image
                                src={el.image}
                                alt={el.name}
                            />
                            <Mask>
                                <Caption>{el.name}</Caption>
                            </Mask>
                        </Slide>))
                    }
                </SlideContainer>
                <Container
                    gap={theme.spacing.m}
                    direction="column"
                    alignItems="flex-start"
                    margin="8rem 0"
                    css={{
                        zIndex: '1'
                    }}
                >
                    <Container
                        gap={theme.spacing.xxs}
                        direction="column"
                        alignItems="flex-start"
                    >
                        <HeadlineText>{STRINGS.homePage.headline}</HeadlineText>
                        <Subtext>{STRINGS.homePage.subtext}</Subtext>
                    </Container>
                    <Container
                        gap={theme.spacing.l}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        {/* <Button onClick={() => state.onboarding = true}>Launch NFTs</Button> */}
                        <Button onClick={() => setShowNewsletter(true)}>{STRINGS.subscriptions.newsletter}</Button>
                    </Container>
                </Container>
            </Container>
            <FormModal
                show={showNewsletter}
                onClose={() => setShowNewsletter(false)}
            />
        </BackgroundColorContainer>
    );
}

export default Headline;