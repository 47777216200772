export const RES = {
    images: {
        icon: 'https://facings.fra1.digitaloceanspaces.com/FACINGS%20Logo%20MASK%20RGB.svg',
        logo: 'https://facings.fra1.digitaloceanspaces.com/FACINGS%20Logo_Horizon_RGB.svg',
        logoVertical: 'https://facings.fra1.cdn.digitaloceanspaces.com/FACINGS%20Logo%20RGB.svg',
        logoNegative: {
            icon: 'https://facings.fra1.cdn.digitaloceanspaces.com/FACINGS%20Logo%20MASK_Negative%20RGB.svg',
            src: 'https://facings.fra1.digitaloceanspaces.com/FACINGS%20Logo_Horizon_Negative_RGB.svg',
            vertical: 'https://facings.fra1.cdn.digitaloceanspaces.com/FACINGS%20Logo_Negative%20RGB.svg',
            alt: 'Facings Logo'
        },

        pack: 'https://darkpinup.nyc3.digitaloceanspaces.com/DarkPinupPackArt.png',
        box: 'https://darkpinup.nyc3.digitaloceanspaces.com/DarkPinupBoxArt.png',
        packAndBox: 'https://nftrippy.fra1.digitaloceanspaces.com/packs.png',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        backgroundLarge: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/background-desktop.png',
        backgroundSmall: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/background-mobile.png'
    },

    videos: {
        packOpenAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_PACK_opening_1K.mp4',
        boxOpenAnimation: 'https://darkpinup.nyc3.digitaloceanspaces.com/darkpinup_BOX_opening_1200x1200.mp4'
    },

    samples: [
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/5__I_Can_See_You_Inks.png'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/cryptowriter/5__I_Can_See_You_Colored.jpg'
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: "https://wax.atomichub.io/market?collection_name=darkpinup"
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: "https://wax.atomichub.io/profile/{0}?collection_name=darkpinup"
        },
        atomicHubEntireCollection: {
            label: "AtomicHub Dark Pinup entire Collection",
            link: "https://wax.atomichub.io/tools/book/darkpinup/{0}"
        },
        waxStash: {
            label: "WAXStash",
            link: "https://www.waxstash.com/markets?collection_name=darkpinup"
        },
        nftHive: {
            label: "NFTHive",
            link: "https://nfthive.io/collection/darkpinup"
        }
    },

    externalLinks: {
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io",
            image: "https://facings.io/assets/powered-by-WAX.svg"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/facings"
        },
        facingsGithub: {
            label: "FACINGS Github",
            link: "https://github.com/facings"
        },
        facingsYouTube: {
            label: "FACINGS YouTube",
            link: "https://www.youtube.com/channel/UClpFrHMc3COBmX7PKPNTONQ"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        eosd: {
            label: "EOSD",
            link: "https://detroitledger.tech",
            image: "https://facings.io/assets/built-with-love.svg"
        },
        eos: {
            label: "EOS",
            link: "https://eosnetwork.com/"
        }
    },

    leadership: [
        {
            name: 'Robert Konsdorf',
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/team/facings-rob-2.png',
            jobDescription: 'Founder & CEO'
        },
        {
            name: 'Keir Kleinknecht',
            srcImg: 'https://facings.fra1.cdn.digitaloceanspaces.com/team/facings-kpl.png',
            jobDescription: 'COO'
        }
    ],

    team: [
        {
            name: 'Edson Bomfim',
            jobDescription: 'Software Engineer'
        },
        {
            name: 'Felblob',
            jobDescription: 'Product Manager'
        },
        {
            name: 'Marcelo Souza',
            jobDescription: 'UI Engineer'
        },
        {
            name: 'Marcos Moreira',
            jobDescription: 'DevOps Engineer'
        },
        {
            name: 'Tim Fesenko',
            jobDescription: 'Sr. Software Engineer, Team Lead'
        }
    ],

    collections: {
        strangeUnusual: {
            label: "Strange & Unusual",
            link: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-strangeunusual-doggy.jpg",
            url: "https://cards.thestrangeandunusual.com"
        },
        yngDna: {
            label: "YNG DNA",
            link: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-young_dna-youth_shade.jpg",
            url: "https://nfts.yng-dna.com/"
        },
        darkPinup: {
            label: "Dark Pinup",
            link: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-dark_pinup-vixen.jpg",
            url: "https://darkpinup.com/"
        },
        skullz: {
            label: "Skullz",
            link: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-skullz-parliament.JPG",
            url: "https://skullzofficial.com/"
        },
        cryptoWriter: {
            label: "Finney Vol. 1",
            link: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-cryptowriter-finneys_arrival.jpg",
            url: "https://cards.finney.world/"
        },
        nftrippy: {
            label: "NFTrippy",
            link: "https://facings.fra1.cdn.digitaloceanspaces.com/collections/facings-nftrippy-shrooman.jpg",
            url: "https://nftrippy.com/"
        },
        fanfavz: {
            label: "FanFavz",
            link: "https://cdn.facings.io/Collection_Retired.png",
            url: "https://hearns.fanfavz.com/"
        }
    }
};

export default RES;
