import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Container from '@components/Container';
import BenefitItem from '@components/BenefitItem';
import STRINGS from '@utils/strings';

const HeadlineText = styled.h1(({ theme }) => ({
    ...theme.typography.h4,
    margin: 0,
    maxWidth: '30ch'
}));

const Grid = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing.l,
    marginRight: 'auto',
    marginLeft: 'auto',
    alignItems: 'start',
    [theme.mediaQuery.largeMobileUp]: {
        gridTemplateColumns: 300
    },
    [theme.mediaQuery.desktopUp]: {
        gridTemplateColumns: '300px 300px'
    }
}));

function Benefits() {
    const theme = useTheme();

    const benefits = STRINGS.benefits.list.map((item, index) => (
        <BenefitItem key={index} {...item} />
    ));

    return (
        <Container
            gap={theme.spacing.xl}
            padding={theme.spacing.xl}
            alignItems="flex-start"
            justifyContent="space-between"
            isFullWidth={false}
        >
            <HeadlineText>{STRINGS.benefits.headline}</HeadlineText>
            <Grid>
                {benefits}
            </Grid>
        </Container>
    );
}

export default Benefits;