import { useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import StyledLink from '@components/Link';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import BurgerIcon from '@components/Burger';
import { SHOW_FAQ, WAX_SIGN_IN, SHOW_BUY_PAGE, SHOW_PACK_HISTORY } from '@utils/globals';
import Login from '@components/Login';
import { withUAL } from 'ual-reactjs-renderer';
import { useSnapshot } from 'valtio';
import { state } from '../globalState';
import StyledButton from './Button';

const StyledHeader = styled.div(({ theme, isHomePage }) => ({
    padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
    height: theme.size.headerHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    maxWidth: theme.size.maxContentWidth,
    margin: 'auto',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: theme.colors.common.background,
    [theme.mediaQuery.largeMobileUp]: {
        padding: `${theme.spacing.xs} ${theme.spacing.xl}`
    }
}));

const Links = styled.div(({ theme, open, isHomePage }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.colors.common.background,
    transform: open ? 'translateY(100%)' : 'translateY(-100%)',
    transition: theme.transition.default,
    padding: theme.spacing.xs,
    [theme.mediaQuery.tabletLandscapeUp]: {
        transform: 'none',
        flexDirection: 'row',
        position: 'relative',
        top: 'initial',
        left: 'initial',
        width: 'initial',
        padding: 0,
        backgroundColor: 'transparent'
    }
}));

const Logo = styled.a(({ theme }) => ({
    cursor: 'pointer',
    height: 44,
    zIndex: 999,
    '&:hover': {
        textDecoration: 'none'
    }
}));

function Header({ ual }) {
    const [ burgerOpen, setBurgerOpen ] = useState(false);
    const theme = useTheme();
    const snap = useSnapshot(state);

    function handleBurgerOpen() {
        setBurgerOpen(false);
    }

    function isHomePage() {
        return location.pathname === '/';
    }

    return (
        <StyledHeader isHomePage={isHomePage()}>
            <Logo href={ROUTES.home} onClick={handleBurgerOpen}>
                <img
                    style={{ height: '100%', objectFit: 'contain' }}
                    src={snap.theme ? RES.images.logoNegative.src : RES.images.logo}
                    alt={RES.images.logoNegative.alt}
                />
            </Logo>
            <Links open={burgerOpen} isHomePage={isHomePage()}>
                <StyledLink header href={ROUTES.home} onClick={handleBurgerOpen}>
                    {STRINGS.header.home}
                </StyledLink>
                <StyledLink header href={ROUTES.about} onClick={handleBurgerOpen}>
                    {STRINGS.header.about}
                </StyledLink>
                <StyledLink header href="https://creator.facings.io" external target="_blank" onClick={handleBurgerOpen}>
                    {STRINGS.header.creator}
                </StyledLink>
                <StyledLink header href={ROUTES.collections} onClick={handleBurgerOpen}>
                    {STRINGS.header.collections}
                </StyledLink>
                <StyledLink header href={`https://facings.medium.com/`} external target="_blank" onClick={handleBurgerOpen}>
                    Blog
                </StyledLink>
                {
                    SHOW_FAQ ?
                        <StyledLink header href={ROUTES.faq} onClick={handleBurgerOpen}>
                            {STRINGS.header.faq}
                        </StyledLink>
                        : null
                }
                {
                    SHOW_BUY_PAGE ?
                        <StyledLink header href={ROUTES.buypacks} onClick={handleBurgerOpen}>
                            {STRINGS.header.shop}
                        </StyledLink>
                        : null
                }
                {
                    WAX_SIGN_IN && ual.activeUser ?
                        <>
                            <StyledLink header href={ROUTES.inventory} onClick={handleBurgerOpen}>
                                {STRINGS.header.inventory}
                            </StyledLink>
                            {
                                SHOW_PACK_HISTORY ?
                                    <StyledLink header href={ROUTES.packrips} onClick={handleBurgerOpen}>
                                        {STRINGS.header.packRips}
                                    </StyledLink> : null
                            }
                        </>
                        :
                        null
                }
                { WAX_SIGN_IN ? <Login /> : null }
                <StyledLink header onClick={() => state.theme = snap.theme ? false : true}>
                    <box-icon name={snap.theme ? 'sun' : 'moon'} type='solid' color={theme.colors.common.foreground} />
                </StyledLink>
            </Links>
            <BurgerIcon burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
        </StyledHeader>
    );
}

export default WAX_SIGN_IN ? withUAL(Header) : Header;
