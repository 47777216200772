import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import theme from '../styles/theme';

const CustomWrapper = styled.label(({ theme, style }) => ({
    ...theme.typography.p,
    fontFamily: '"Mulish", sans-serif',
    fontSize: '1.27rem',
    color: theme.colors.tertiary,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: theme.spacing.s,
    padding: '2rem 0 0',
    marginBottom: theme.spacing.xs,
    cursor: 'pointer',
    '& box-icon': {
        marginRight: '0.5rem',
        fill: `${theme.colors.grey.intensity200}!important`,
        color: `${theme.colors.grey.intensity200}!important`
    },
    ...style
}));

function StyledCheckbox({ id, label, style, initialValue, getState }) {
    function toggleCheck() {
        // isChecked ? setIsChecked(false) : setIsChecked(true);
        setIsChecked(!isChecked);
        getState(id, !isChecked);
    }

    const [ isChecked, setIsChecked ] = useState(initialValue ? initialValue : false);

    return (
        <CustomWrapper
            onClick={toggleCheck}
            onKeyDown={toggleCheck}
            role='presentation'
            style={style}
        >
            { isChecked ? <box-icon name='checkbox-checked' type='solid' size="lg"  /> : <box-icon name='checkbox' type='solid' size="lg"  /> }
            {label}
        </CustomWrapper>
    );
}

export default StyledCheckbox;
