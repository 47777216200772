
import ROUTES from '@utils/routes';
export const STRINGS = {
    appName: 'FACINGS',
    homePage: {
        headline: 'Engaging NFTs, made easy',
        subtext: "FACINGS enables creators to manage all aspects of their NFT collections with an easy-to-use and flexible self-service platform, no web3 experience necessary. FACINGS helps creators reach their audiences, by offering a delightful and intuitive collector experience, from purchasing to pack opening, and more.  Join the FACINGS community on Discord to get news and updates.",
        ctaButton: 'Subscribe to our newsletter'
    },

    header: {
        home: "Home",
        packRips: "Pack Rips",
        inventory: "Inventory",
        leaderboard: "Leaderboard",
        faq: "FAQ",
        about: "About",
        collections: "Collections",
        creator: "Creator"
    },

    about: {
        missionTitle: "Our Mission",
        mission: "FACINGS empowers creators by giving them tools to manage NFT collections with ease, streamlining the path to rich digital experiences for everyone.",
        leadership: "Our Leadership",
        leadershipDescription: "Rob Konsdorf and Keir Kleinknecht are passionate NFT evangelists and growth-minded entrepreneurs with collectively over 13 years in the blockchain industry, and over 30 years of startup experience. Together they lead FACINGS’ passionate team.",
        join: "Join our crew",
        joinDescription: "FACINGS is a remote-first, international software startup. Our team inhabits all four corners of the globe. If you dream big and build bigger, pass along your CV.",
        joinPassionate: "Passionate about NFTs? Talented, driven, team oriented? Please tell us all about how you will take FACINGS to the next level!",
        joinButton: "Join us!"
    },

    collections: {
        newCollection: "New collections built with FACINGS launch every day!",
        newCollectionSubtext: "Want help getting your NFT campaign off the ground?",
        newCollectionButton: "Launch your own collection",
        collectionHeadline: "Our Collections",
        collectionName: "Collection Name",
        packsAvailable: "Launch Date",
        packsOpenable: "Packs Openable",
        strangeUnusual: {
            name: "The Strange & Unusual",
            subtext: "The Strange and Unusual is an all-new NFT collection straight from the heart of The Strange and Unusual Oddities Parlor in Kingston, PA. This one-of-a-kind curio collection showcases the extraordinary and the bizarre: from long-forgotten personal effects to unusual curiosities.",
            info: {
                name: "oddityparlor",
                available: "October 8th 2021"
            }
        },
        darkPinup: {
            name: "Dark Pinup",
            subtext: "'Dark Pinup is a subgenre of pinup photography I have established over the last 20 years of my career. In the last decade, it has become a subculture, growing far beyond my work. This collection is the first time I've made my original photography available as digital assets. The photography I'll be listing are the images that started an entire movement within the rockabilly and pinup scene.' - Lars Kommienezuspadt",
            info: {
                name: "darkpinup",
                available: "May 19th 2021"
            }
        },
        skullz: {
            name: "Skullz",
            subtext: "Skullz, the all-new metaverse band, debuted with their inaugural NFT collection on WAX. The rarest NFTs in the collection include pre-release clips of Skullz songs! Stay tuned for more news from the Skullz team on their first metaverse concert.",
            info: {
                name: "skullz",
                available: "July 8th 2021"
            }
        },
        cryptoWriter: {
            name: "Finney Vol. 1",
            subtext: "Join Finney on an epic adventure in Volume 1: Immutable Journey, a breathtaking, comic-style pack release, featuring 21 pieces of original art by Lars Kommienezuspadt and team with narration by Mark Bailey. Those familiar with the promo release of Finney’s Arrival, will now discover what happened from the moment Finney set foot on earth, to his first encounter with the diabolical Mr. Shift, on to the discovery of the mysterious device. See what awaits Finney in this creative and thrilling collection.",
            info: {
                name: "writer",
                available: "August 20th 2021"
            }
        },
        nftrippy: {
            name: "NFTrippy",
            subtext: "NFTrippy is a mesmerizing collection of educational and beautiful mushrooms. This collection is the world’s 1st #NFTCheimstrySet - designed with unique crating mechanics that allow the discovery of new mushrooms through combining existing ones. Collectors are in for a trip with this release.",
            info: {
                name: "nftrippy",
                available: "September 14th 2021"
            }
        },
        darkPinupAct2: {
            name: "Dark Pinup Act 2",
            subtext: "Dark Pinup is back in the long awaited Act 2 collection. Follow the elegant and timeless stories told through Lars Kommienezuspadt’s photography. Stay tuned for more information on Dark Pinup Act 2.",
            info: {
                name: "darkpinup",
                available: "Fall, 2021"
            }
        },
        stayTunedText: "New collections built with FACINGS launch every day! Stay tuned for new releases by joining our email list.",
        stayTunedButton: "Stay Tuned"
    },

    benefits: {
        headline: "FACINGS gives creators everything they need to launch engaging campaigns, no crypto or web3 experience needed.",
        list: [
            {
                headline: "Full-service collection publishing",
                content: "Easily upload content, plan launches, and publish to your own site."
            },
            {
                headline: "Let us handle blockchain security",
                content: "Focus on your use case, not technical complexity."
            },
            {
                headline: "Flexible payment methods",
                content: "Accept credit cards or cryptocurrency."
            },
            {
                headline: "Fluid Customization of NFTs",
                content: "Create unique NFTs with flexible set designs."
            }
        ]
    },

    marketing: {
        headline: "The FACINGS ecosystem includes a variety of products & services to take advantage of — no web3 experience necessary. Empowering creators to be their own publisher.",
        list: [
            {
                headline: "Oversee your collections",
                content: "Create a collection and manage your NFTs solo or as a team. View change history, assign roles, and stay in sync."
            },
            {
                headline: "Manage your launches",
                content: "Define every facet of your NFT launch including the supply, price, release date, launch site, branding, and features."
            },
            {
                headline: "Gamify your NFTs",
                content: "Bring your NFTs to life by adding exciting utility using the expansive plug-in system."
            }
        ]
    },

    subscriptions: {
        aboveHeadline: "Get Early Access to FACINGS",
        headline: "Ready to start planning your next launch?",
        description: "Join the FACINGS community on Discord to discuss the latest ongoings, share and trade your favorite NFTs, promote your new launch, and connect with other like-minded people.",
        artist: {
            headline: "I’m a brand or artist",
            description: "FACINGS is coming this winter! Sign up for information on the FACINGS Platform launch.",
            buttonText: "Join FACINGS",
            modalTitle: "Be the first to know about our service's availability",
            successMessage: "Thank you!"
        },
        collector: {
            headline: "I’m an NFT collector",
            description: "Discover new opportunities as a FACINGS NFT collector by joining us in our releases.",
            buttonText: "Get NFT launch alerts",
            modalTitle: "Receive the latest news on our campaigns",
            successMessage: "Thank you!"
        },
        concierge: {
            headline: "I need help launching an NFT collection NOW.",
            description: "Learn more about how the FACINGS team has helped brands launch successful NFTs campaigns.",
            buttonText: "Get in touch",
            modalTitle: "Send us information on your project and we'll be reaching out.",
            successMessage: "Thank you!"
        },
        newsletter: 'Keep me informed'
    },

    faqItems: [
        {
            question: "Who is FACINGS?",
            answer: "<p>FACINGS is a publisher of high quality NFT projects. They specialize in launching innovative and unique collections using state of the art technology. FACINGS’ tech is engineered from the ground up to improve the expected user experience on WAX and gives collectors a fun and safe way to collect the digital NFTs they want.</p> <p>FACINGS has teamed up with The Strange and Unusual to provide a custom built pack opening and crafting experience that is unmatched on WAX today. It is designed to reduce the frustrations and defects that users dealt with in other past campaigns.</p>"
        },
        {
            question: "What are NFTs?",
            answer: "NFT stands for Non Fungible Token and is a way of creating unique digital collectible assets. Refer to this article for more information on NFTs."
        },
        {
            question: "Are NFTs Bad For The Environment?",
            answer: "While all NFTs do use electricity to be produced, the amount that is consumed on the WAX blockchain is 125,000 times less than Ethereum. In fact, WAX is carbon neutral. WAX NFTs have offset almost 4 million tons of carbon emissions by not being minted on the Ethereum blockchain. As technology improves, WAX will continue to use less energy and find ways to use renewable sources for the energy it does consume."
        },
        {
            question: "What is WAX?",
            answer: "WAX is a blockchain designed for collectibles like The Strange and Unusual Exhibit A release. They describe their mission as bringing NFTs to the mass market in the “safest, most secure, environmentally friendly, and easy-to-use marketplace in the world.” WAX does not use nearly the amount of electricity to operate and does not cost the user fees to conduct transactions, which is common on other top chains. By collecting NFTs on WAX, you support an environmentally safe solution to collecting digitally scarce goods."
        },
        {
            question: "What Else Should I Know?",
            answer: "<p><b>Promo NFT</b>: Sign up for more information on the website and you will be eligible to claim a Free NFT before launch. There will be a time based drop for the NFT on AtomicHub limited to only those who signed up on the website before the drop time. We will announce the cutoff for signups and the time and location of the drop soon.</p><p><b>Wax Cloud Wallet</b>: Wax Cloud Wallet (“WCW”) is an easy to use way to store and manage your NFTs on the WAX blockchain. When users sign up or sign in to WAX Cloud Wallet, a WAX blockchain account is automatically created associated with your email address. Secure your wallet with 2FA for the most user friendly and safe custodial wallet in blockchain.</p>"
        },
        {
            question: "How do I trade WAX Packs or cards to someone else?",
            answer: "You can transfer your NFTrippy WAX Packs and cards to someone else by visiting your WAX Cloud Wallet NFT inventory and clicking on the transfer icon on the top of the NFT. Then enter the wallet name of the community member that you wish to trade with and click Send."
        },
        {
            question: "How do I know my NFTs are authentic?",
            answer: "Always refer to the verified checkmark on the collection to ensure your NFTs are created by NFTrippy under the \"nftrippy\" author account."
        },
        {
            question: "How can I get support?",
            answer: "Please email hello@facings.io for support."
        }
    ],

    footer: {
        textUnderLogo: "Engaging NFTs, made easy.",
        companyName: "FACINGS INC.",
        companyAddressLine1: "1570 Woodward Ave. FL 3",
        companyAddressLine2: "48226",
        companyAddressLine3: "Detroit, Michigan, U.S.A.",
        copyright: "© 2023 FACINGS INC. All rights reserved."
    },
    onboarding: {
        intro: {
            title: 'Join FACINGS and launch your own NFTs.',
            body: '<em>Get an exclusive invite to our upcoming products and features.</em><br />We’re thrilled to learn more about your project.'
        },
        outro: {
            title: 'Thank You For Registering',
            body: 'Get an exclusive invite to our platform to help you launch your next NFT collection. In the meantime, see what’s happening here, at Facings — <a href="https://facings.medium.com/" target="_blank" rel="noreferrer">visit our blog</a>.'
        }
    },
    logIn: "Log In",
    logOut: "Log Out",
    notFound: "Not Found",
    loading: "Loading",
    loadingEl: "Loading...",
    confirm: "Confirm",
    cancel: "Cancel",
    close: "Close",
    error: "Error",
    defaultErrorMessage: "An error has occurred!",

    goToHome: "Home Page",

    day: "day",
    days: "days",
    hour: "hour",
    hours: "hours",
    minute: "minute",
    minutes: "minutes",
    second: "second",
    seconds: "seconds"
};

export default STRINGS;
