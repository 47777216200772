import styled from "@emotion/styled";
import * as GLOBAL_VARS from '@utils/globals';
import RES from '@utils/resources';

const AnimationBackgroundOverlay = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.backgroundColor,
    position: 'fixed',
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999
}));

function OpenAnimation({ showAnimation, onAnimationEnd, templateId }) {
    return  showAnimation ?
        <AnimationBackgroundOverlay>
            <video autoPlay
                playsInline
                width="100%"
                height="100%"
                onEnded={onAnimationEnd}
            >
                <source
                    src={templateId === GLOBAL_VARS.PACK_TEMPLATE_ID ? RES.videos.packOpenAnimation : RES.videos.boxOpenAnimation}
                    type="video/mp4"
                />
            </video>
        </AnimationBackgroundOverlay> : null;
}

export default OpenAnimation;
