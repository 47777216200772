import styled from '@emotion/styled';
import { useState } from 'react';
import { validateEmail } from '../utils/formValidations';

const CustomInputLabel = styled.label(({ theme }) => ({
    ...theme.typography.p,
    fontFamily: '"Mulish", sans-serif',
    fontSize: '1.27rem',
    color: theme.colors.tertiary,
    position: 'relative',
    marginBottom: theme.spacing.s,
    width: '100%',
    display: 'block',
    zIndex: 1,
    padding: '2rem 0.5rem 0',
    '&.alert input': {
        borderColor: theme.colors.primary.main
    }
}));

const CustomInput = styled.input(({ theme }) => ({
    ...theme.typography.pBold,
    fontSize: '1.27rem',
    position: 'absolute',
    left: 0,
    bottom: '-1rem',
    height: '3.25rem',
    color: theme.colors.common.foreground,
    appearance: 'none',
    borderStyle: 'solid',
    border: 0,
    borderBottom: `3px solid ${theme.colors.grey.intensity800}`,
    marginTop: theme.spacing.xxs,
    padding: `${theme.spacing.xxxs} ${theme.spacing.xxs}`,
    outline: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
    '&:placeholder': {
        color: theme.colors.tertiaryLighter,
        opacity: 0.5
    },
    '&:focus, &:active': {
        borderColor: theme.colors.common.foreground
    },
    '&:focus + span, &:active + span': {
        display: 'block',
        transform: 'scale(.8) translateY(-2.25rem)'
    },
    '&:disabled, &:disabled + span': {
        opacity: 0.5,
        cursor: 'not-allowed'
    }
}));

const LabelText = styled.span(({ theme }) => ({
    transition: 'all ease-in-out 360ms',
    transformOrigin: 'left center',
    '&.focused': {
        display: 'block',
        transform: 'scale(.8) translateY(-1.9rem)',
        transition: 'all ease-in-out 360ms'
    }
}));

const InfoText = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.secondaryLight,
    marginTop: theme.spacing.xxxs
}));

function StyledInput({ id, label, placeholder, type, infoText, style, getState, initialValue, validate = false, disabled, ...otherProps }) {

    const [ value, setValue ] = useState(initialValue ? initialValue : '');
    const [ valid, setValid ] = useState(validate);

    const isEmptyValue = (target) => {
        const sibling = target.nextElementSibling;
        target.value ? sibling.classList.add('focused') : sibling.classList.remove('focused');
    };

    const handleChange = (e) => {
        e.preventDefault();
        const el = e.target;

        isEmptyValue(el);

        setValue(el.value);
        getState(id, el.value);
    };

    return (
        <div>
            <CustomInputLabel className={valid ? 'alert' : ''}>
                <CustomInput type={type} placeholder={placeholder} value={value} {...otherProps} onChange={(e) => handleChange(e)} disabled={disabled} />
                <LabelText className={initialValue ? 'focused' : ''}>{label}</LabelText>
                {infoText ? <InfoText>{infoText}</InfoText> : ''}
            </CustomInputLabel>
        </div>
    );
}

export default StyledInput;
