import React, { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

const Plate = styled.div(({ theme, c, meter = 571 }) => ({
    textAlign: 'center',
    margin: '20px',
    '& circle': {
        fill: 'none'
    },
    '& .circle-back': {
        stroke: theme.colors.grey.intensity400,
        strokeWidth: '14px'
    },
    '& .circle-front': {
        transform: 'rotate(270deg)',
        stroke: theme.colors.primary.main,
        strokeWidth: '21px',
        strokeDasharray: c,
        strokeDashoffset: meter,
        animation: 'loading 1.25s ease',
        animationIterationCount: 'infinite',
        transition: 'all ease-in-out 320ms'
    }
}));

function Donut({ total, progress, radius, area }) {
    const theme = useTheme();
    /* Circumfrance = 2 * Math.PI * radius + 1 */
    const circumfrance = 2 * Math.PI * radius;

    return (
        <Plate className="donut" c={circumfrance} meter={circumfrance - ((circumfrance/total) * progress)}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                width={area} height={area} xmlSpace="preserve"
            >

                <g transform={`translate(${area/2}, ${area/2})`}>
                    <circle cx="0" cy="0" r={radius} className="circle-back" />
                    <circle cx="0" cy="0" r={radius} className="circle-front" />
                </g>
            </svg>
        </Plate>
    );
}

export default Donut;
