import styled from '@emotion/styled';
import { Link } from "react-router-dom";
import WithRouter from '@components/WithRouter';

const CustomLink = styled(Link)(({ theme, header, tab, isActive }) => {
    let typography = header ? theme.typography.h5 : theme.typography.p;
    let headerStyle = header ? {
        marginLeft: 0,
        marginBottom: theme.spacing.s,
        [theme.mediaQuery.tabletLandscapeUp]: {
            marginLeft: theme.spacing.m,
            marginBottom: 0
        }
    } : {};
    let padding = tab ? {
        padding: `${theme.spacing.xs} ${theme.spacing.s}`
    } : {};

    return {
        ...typography,
        color: isActive() ? theme.colors.grey.intensity400 : theme.colors.common.foreground,
        marginLeft: theme.spacing.s,
        textDecoration: 'none',
        opacity: 1,
        transition: 'all ease-in-out 160ms',
        '&:hover': {
            transform: isActive() ? 'none' : 'translateY(-0.15rem)',
            cursor: isActive() ? 'default' : 'pointer'
        },
        '&:visited': {
            color: theme.colors.common.foreground
        },
        ...padding,
        ...headerStyle
    };
});

const ExternalLink = styled.a(({ theme, header, tab, isActive }) => {
    let typography = header ? theme.typography.h5 : theme.typography.p;
    let headerStyle = header ? {
        marginLeft: 0,
        marginBottom: theme.spacing.s,
        [theme.mediaQuery.tabletLandscapeUp]: {
            marginLeft: theme.spacing.m,
            marginBottom: 0
        }
    } : {};
    let padding = tab ? {
        padding: `${theme.spacing.xs} ${theme.spacing.s}`
    } : {};

    return {
        ...typography,
        color: isActive() ? theme.colors.grey.intensity400 : theme.colors.common.foreground,
        marginLeft: theme.spacing.s,
        textDecoration: 'none',
        opacity: 1,
        transition: 'all ease-in-out 160ms',
        '&:hover': {
            transform: isActive() ? 'none' : 'translateY(-0.15rem)',
            cursor: isActive() ? 'default' : 'pointer'
        },
        '&:visited': {
            color: theme.colors.common.foreground
        },
        ...padding,
        ...headerStyle
    };
});

function StyledLink({ href, children, header, tab, onClick, style, target, external, router }) {
    const { location } = router;

    function isActive() {
        if (href && location) {
            return location.pathname.includes(href.slice(2));
        }
    }

    if (external) {
        return (
            <ExternalLink href={href} header={header} tab={tab} target={target} isActive={isActive} onClick={onClick}>{children}</ExternalLink>
        );
    }

    return (
        <CustomLink to={href} header={header} tab={tab} target={target} isActive={isActive} onClick={onClick}>{children}</CustomLink>
    );
}

export default WithRouter(StyledLink);
