import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import Container from '@components/Container';

const Headline = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.common.foreground || theme.colors.common.background,
    lineHeight: '160%'
}));

const Paragraph = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xs
}));

const Number = styled.p(({ theme }) => ({
    ...theme.typography.subtitle,
    color: theme.colors.primary.main
}));

function MarketingItem({ headline, content, number }) {
    const theme = useTheme();

    const itemNumber = `0${number + 1}`;

    const containerStyle = {
        borderLeft: `1px solid ${theme.colors.grey.intensity800}`
    };

    return (
        <Container
            gap={theme.spacing.xxs}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            padding={theme.spacing.xs}
            style={containerStyle}
        >
            <Number>{itemNumber}</Number>
            <Headline>{headline}</Headline>
            <Paragraph>{content}</Paragraph>
        </Container>
    );
}

export default MarketingItem;