import React from "react";
import styled from "@emotion/styled";

const FlexContainer = styled.div(({ position, direction, alignItems, justifyContent, gap, padding, margin, isFullWidth, flexWrap, style, theme }) => ({
    display: "flex",
    flexDirection: 'column',
    flexWrap: flexWrap,
    width: '100%',
    maxWidth: isFullWidth ? 'none' : theme.size.maxContentWidth,
    alignItems: alignItems,
    justifyContent: justifyContent,
    gap: gap,
    paddingTop: padding,
    paddingBottom: padding,
    paddingLeft: padding !== 0 ? theme.spacing.xs : 0,
    paddingRight: padding !== 0 ? theme.spacing.xs : 0,
    margin: margin,
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: direction,
        padding: padding
    },
    [ theme.mediaQuery.desktopUp ]: {
        margin: `${margin} auto ${margin} auto`
    },
    ...style
}));

function Container({
    position = "static",
    direction = "row",
    alignItems = "center",
    justifyContent = "center",
    gap = 0,
    padding = 0,
    margin = 0,
    isFullWidth = true,
    flexWrap,
    style,
    children
}) {
    return (
        <FlexContainer
            position={position}
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}
            padding={padding}
            margin={margin}
            gap={gap}
            style={style}
            isFullWidth={isFullWidth}
            flexWrap={flexWrap}
        >
            {children}
        </FlexContainer>
    );
}

export default Container;
