export const isValidWalletAddress = (wallet) => {
    return wallet === '' || !/[^a-zA-Z1-5.]/.test(wallet) && wallet.length <= 12 && !wallet.endsWith('.') ? true : false;
};

export const isValidName = (name) => {
    return name && !/[^a-zA-Z0-9'-/ ]/.test(name) && name !== '' ? true : false;
};

export const isNotEmpty = (field) => {
    return field !== '' ? true : false;
};

export const isSelected = (select) => {
    return !select.options[select.selectedIndex].hasAttribute('disabled') ? true : false;
};

export const validateEmail = (email) => {
    //eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};