import { proxy } from "valtio";

const d = new Date();
let hour = d.getHours();

const state = proxy({
    theme: hour > 18 || hour < 6,
    onboarding: false
});

export { state };