import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Container from '@components/Container';
import MarketingItem from '@components/MarketingItem';
import STRINGS from '@utils/strings';

const HeadlineText = styled.h1(({ theme }) => ({
    ...theme.typography.h2,
    margin: 0,
    maxWidth: '50ch'
}));

const Grid = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing.l,
    marginRight: 'auto',
    marginLeft: 'auto',
    alignItems: 'start',
    gridTemplateColumns: 290,
    [theme.mediaQuery.largeMobileUp]: {
        gridTemplateColumns: 300
    },
    [theme.mediaQuery.desktopUp]: {
        gridTemplateColumns: '300px 300px'
    }
}));

const BackgroundColorContainer = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.grey.intensity900,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}));

function Marketing() {
    const theme = useTheme();

    const Marketing = STRINGS.marketing.list.map((item, index) => (
        <MarketingItem key={index} {...item} number={index} />
    ));

    return (
        <BackgroundColorContainer>
            <Container
                direction="column"
                gap={theme.spacing.xl}
                isFullWidth={false}
                alignItems="left"
                padding={theme.spacing.xl}
            >
                <HeadlineText>{STRINGS.marketing.headline}</HeadlineText>
                <Container
                    direction="row"
                    alignItems="stretch"
                    gap={theme.spacing.m}
                    isFullWidth={false}
                >
                    {Marketing}
                </Container>
            </Container>

        </BackgroundColorContainer>
    );
}

export default Marketing;