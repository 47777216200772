import { Route, Routes, Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Home from '@context/Home/Home';
import BuyPacks from '@context/BuyPacks/BuyPacks';
import Inventory from '@context/Inventory';
import NotFound from '@context/NotFound';
import PackRips from '@context/PackRips';
import About from '@context/About';
import FAQ from '@context/FAQ';
import Privacy from '@context/Privacy';
import Terms from '@context/Terms';
import License from '@context/License';
import SubscriptionSection from '@components/SubscriptionSection';
import FocusedForm from '../components/FocusedForm';
import Collections from '@context/Collections';
import ROUTES from '@utils/routes';
import STRINGS from '../utils/strings';
import onboardingForm from '@utils/onboardingFormData.component';
import { SHOW_FAQ, WAX_SIGN_IN, SHOW_HEADER, SHOW_BUY_PAGE, SHOW_PACK_HISTORY } from '@utils/globals';
import { withUAL } from 'ual-reactjs-renderer';
import { state } from '../globalState';
import { useSnapshot } from 'valtio';
import WithRouter from '@components/WithRouter';

const Container = styled.div(({ theme }) => ({
    width: '100%',
    minHeight: `calc(100vh - ${theme.size.headerHeight} - ${theme.size.footerHeight})`,
    margin: SHOW_HEADER ? `${theme.size.headerHeight} 0px 0px 0px` : 0
}));

function Content({ ual, router }) {

    const snap = useSnapshot(state);
    const { location, navigate } = router;

    if (WAX_SIGN_IN && !ual.activeUser) {
        if (location.pathname.includes(ROUTES.inventory)) {
            navigate(ROUTES.home);
        }
    }

    return (
        <Container>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path={ROUTES.home} element={<Home />} />
                <Route path={ROUTES.about} element={<About />} />
                <Route path={ROUTES.collections} element={<Collections />} />
                <Route path={ROUTES.privacy} element={<Privacy />} />
                <Route path={ROUTES.terms} element={<Terms />} />
                <Route path={ROUTES.license} element={<License />} />
                {
                    SHOW_BUY_PAGE ?
                        <Route path={ROUTES.buypacks} element={<BuyPacks />} />
                        : null
                }
                {
                    SHOW_FAQ ?
                        <Route path={ROUTES.faq} element={<FAQ />} />
                        : null
                }
                {
                    WAX_SIGN_IN ?
                        <>
                            <Route path={ROUTES.inventory} element={<Inventory />} />
                            { SHOW_PACK_HISTORY ? <Route path={ROUTES.packrips} element={<PackRips />} /> : null }
                        </>
                        : null
                }
                <Route path={ROUTES.notfound} element={<NotFound />} />
                <Route path='*' element={<Navigate to={ROUTES.notfound} />} />
            </Routes>
            <SubscriptionSection />
            <FocusedForm
                show={snap.onboarding}
                intro={STRINGS.onboarding.intro}
                outro={STRINGS.onboarding.outro}
                data={onboardingForm}
            />
        </Container>
    );
}

export default WAX_SIGN_IN ? withUAL(WithRouter(Content)) : WithRouter(Content);
