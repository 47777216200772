import { SHOW_FAQ, SHOW_BUY_PAGE, WAX_SIGN_IN } from '@utils/globals';

const ROUTES = {
    home: 'home',
    buypacks: SHOW_BUY_PAGE ? 'shop' : 'not_found',
    about: 'about',
    collections: 'collections',
    faq: SHOW_FAQ ? 'faq' : 'not_found',
    inventory: WAX_SIGN_IN ? 'inventory' : 'not_found',
    packrips: WAX_SIGN_IN ? 'pack_rips': 'not_found',
    view: WAX_SIGN_IN ? 'view' : 'not_found',
    privacy: 'privacy',
    terms: 'terms',
    license: 'nft-license',
    notfound: 'not_found'
};

export default ROUTES;
