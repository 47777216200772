import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Donut from '@components/Donut';
import Button from '@components/Button';
import RES from '@utils/resources';
import { RECAPTCHA_KEY } from '@utils/globals';
import { state } from '../globalState';
import { useSnapshot } from 'valtio';
import { load } from 'recaptcha-v3';
import theme from '../styles/theme';

const OnboardingContainer = styled.div(({ theme }) => ({
    fontFamily: '"Mulish", sans-serif',
    position: 'fixed',
    zIndex: '1000',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.colors.common.background,
    display: 'grid',
    gridTemplateColumns: '320px auto',
    transition: 'opacity ease-in-out 320ms',
    '&.hide': {
        opacity: '0',
        pointerEvents: 'none'
    },
    '&.grid-one': {
        gridTemplateColumns: 'auto'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        gridTemplateColumns: 'auto'
    }
}));

const Sidebar = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '3rem 0',
    backgroundColor: theme.colors.grey.intensity900,
    '&.hide': {
        display: 'none'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        position: 'fixed',
        display: 'block',
        bottom: '0',
        paddingBottom: '2rem',
        height: '5rem',
        width: '100vw',
        borderBottom: `4px solid ${theme.colors.primary.main}`
    }
}));

const ProgressBar = styled.div(({ theme }) => ({
    position: 'relative',
    '&.hide': {
        display: 'none'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        width: '100vw',
        '& > div:first-child': {
            display: 'none'
        }
    }
}));

const ProgressCounter = styled.div(({ theme }) => ({
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& h1': {
        position: 'relative',
        display: 'inline-block',
        fontSize: '4.5rem',
        fontWeight: '600',
        letterSpacing: '-0.04em',
        lineHeight: '1',
        top: '-0.5rem',
        '&:after': {
            content: '"%"',
            fontSize: '40%',
            fontWeight: '500',
            position: 'absolute',
            top: '0.6rem',
            width: '2rem'
        }
    },
    '& span': {
        fontWeight: '600',
        display: 'inline-block',
        letterSpacing: '0.15em',
        width: '1.27rem',
        '&:first-child': {
            textAlign: 'left'
        },
        '&:last-child': {
            textAlign: 'right'
        }
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 2rem',
        '& h1': {
            fontSize: '3rem'
        }
    }
}));

const Main = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
        margin: '2rem 0'
    },
    '& h1': {

    },
    '& a': {
        cursor: 'pointer',
        color: theme.colors.common.foreground,
        fill: theme.colors.common.foreground
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        padding: '0 2rem',
        maxWidth: '100vw',
        '& > *': {
            maxWidth: '100%',
            margin: '0 2rem'
        },
        '& br': {
            display: 'none',
            width: '1rem'
        }
    }
}));

const ActionBar = styled.div(({ theme }) => ({
    position: 'relative',
    width: '436px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
        width: '100%'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        margin: '0.5rem 0!important'
    }
}));

const Question = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    fontWeight: '900',
    letterSpacing: '0.02em',
    marginBottom: '1rem',
    textAlign: 'center'
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.foreground,
    width: '550px',
    maxWidth: '100%',
    textAlign: 'center',
    margin: 'auto'
}));

const StepListItem = styled.div(({ theme }) => ({
    ...theme.typography.pLead,
    display: 'flex',
    justifyContent: 'space-between',
    width: '220px',
    margin: '0.5rem auto',
    '& span': {
        ...theme.typography.subtitle,
        letterSpacing: '0.08em',
        backgroundColor: theme.colors.grey.intensity400,
        padding: '0.25rem 1rem',
        borderRadius: '1rem'
    },
    '&.inactive': {
        opacity: '0.8'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        display: 'none'
    }
}));

const Logo = styled.img(({ theme }) => ({
    width: '150px',
    '&.hide': {
        display: 'none'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        display: 'none'
    }
}));

const CloseButton = styled.div(({ theme }) => ({
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    cursor: 'pointer',
    '& box-icon': {
        fill: theme.colors.grey.intensity300
    },
    '&:hover box-icon': {
        fill: `${theme.colors.common.foreground}!important`
    }
}));

function FocusedForm({ show, intro, outro, data }) {

    const snap = useSnapshot(state);

    const [ captcha, setCaptcha ] = useState({
        score: 0,
        success: false
    });
    const [ progress, setProgress ] = useState(-1);
    const [ formValues, setFormValues ] = useState({});

    const renderedForm = data(formValues, setFormValues);
    const currentStep = renderedForm[progress];
    const totalSteps = renderedForm.length;
    const currentStepPercentage = Math.floor((progress/totalSteps) * 100);

    const submitForm = () => {
        // if (validateEmail(formValues.email)) {return console.log('Submit ready');}
        // fetch('https://api.facings.io/crm/v1/contacts/add', {
        fetch('https://pfh2zwizzql2k3kkqijdpylme40kroed.lambda-url.us-east-1.on.aws/crm/v1/contacts/add', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formValues)
        })
            .then(res => res)
            .catch(err => err);
        if (progress === totalSteps) {closeForm();}
    };

    const closeForm = () => {
        return state.onboarding = false;
    };

    const actionsBarElements = (step, disabled = false) => {
        if (step === totalSteps) {
            return [
                <Button key={'Edit again'} secondary="true" onClick={() => setProgress(0)}>Edit</Button>,
                <Button key={'finish'} onClick={submitForm} disabled={captcha.score < 0.51 || !captcha.success}>Finish</Button>
            ];
        } else if (step === -1) {
            return [
                <Button key={'cancel'} secondary="true" onClick={closeForm}>Cancel</Button>,
                <Button key={'continue'} onClick={() => setProgress(progress + 1)}>Get Started</Button>
            ];
        } else {
            return [
                <Button key={'cancel'} onClick={() => setProgress(progress - 1)} secondary="true">Back</Button>,
                <Button key={'continue'} onClick={() => setProgress(progress + 1)} disabled={disabled}>Next</Button>
            ];
        }
    };

    const progressOfTypes = () => {
        const typeCounter = {};
        const result = [];

        renderedForm.forEach(e => {
            const type = e.type;
            if (typeCounter[type]) {
                typeCounter[type] = typeCounter[type] + 1;
            } else {
                typeCounter[type] = 1;
            }
        });

        let step = progress + 1;
        for (const [ type, steps ] of Object.entries(typeCounter)) {
            if (step > steps) {step -= steps;}
            result.push(
                <StepListItem className={renderedForm[progress].type === type ? '' : 'inactive'} key={typeCounter[type]}>
                    <strong>{type}</strong>
                    <span>
                        { progress > -1 &&
                          progress < totalSteps &&
                          renderedForm[progress].type === type ?
                            `${step} / ${typeCounter[type]}` : typeCounter[type]}
                    </span>
                </StepListItem>
            );
        }

        return result;
    };

    useEffect(() => {
        if (progress === 1 && (captcha.score > 0.50 || captcha.success)) {submitForm();}
        return progress > totalSteps || progress < -1 ? setProgress(-1) : setProgress(progress);
    }, [progress]);

    // useEffect(() => console.log(formValues), [formValues]);

    useEffect(() => {
        async function asyncFunction() {
            const recaptcha = await load(RECAPTCHA_KEY);
            const token = await recaptcha.execute('check_every_step');

            // await fetch('https://api.facings.io/captcha/v1/evaluate', {
            await fetch('https://ps6en6ihlbh6piql2uocnuag6u0iccsh.lambda-url.us-east-1.on.aws/captcha/v1/evaluate', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token })
            })
                .then(res => res.json())
                .then(body => {
                    setCaptcha({
                        score: body.score,
                        success: body.success
                    });
                })
                .catch(err => err);
        }
        asyncFunction();
    }, [progress]);

    // useEffect(() => console.log(captcha), [captcha]);
    // useEffect(() => currentStep ? console.log(currentStep.required.every(e => formValues[e]), [currentStep]) : console.log('Not a form'));

    return (
        <OnboardingContainer className={`${show ? '' : 'hide'} ${progress > -1 ? '' : 'grid-one'}`}>
            <Sidebar className={`progress-bar ${progress > -1 ? '' : 'hide'}`}>
                <Logo src={snap.theme ? RES.images.logoNegative.vertical : RES.images.logoVertical}  alt={RES.images.logoNegative.alt} />
                <ProgressBar>
                    <Donut total={totalSteps} progress={progress} radius="110" area="260" />
                    <ProgressCounter>
                        <h1>{currentStepPercentage}</h1>
                        <div>
                            <span>{progress}</span>
                            <span>/</span>
                            <span>{totalSteps}</span>
                        </div>
                    </ProgressCounter>
                </ProgressBar>
                {
                    progress === -1 || progress === totalSteps ? '' : progressOfTypes()
                }
            </Sidebar>
            <Main>
                <Logo className={progress === -1 ? '' : 'hide'} src={snap.theme ? RES.images.logoNegative.vertical : RES.images.logoVertical}  alt={RES.images.logoNegative.alt} />
                <div>
                    { progress === -1 ? <div>
                        <Question>{intro.title}</Question>
                        <Subtext dangerouslySetInnerHTML={{ __html: intro.body }} />
                    </div> : '' }

                    { currentStep ? currentStep.jsx : '' }

                    { progress === totalSteps ? <div>
                        <Question>{outro.title}</Question>
                        <Subtext dangerouslySetInnerHTML={{ __html: outro.body }} />
                    </div> : '' }
                </div>
                <ActionBar>
                    {currentStep && currentStep.required.every(e => formValues[e]) ?
                        actionsBarElements(progress) : actionsBarElements(progress, true)}
                </ActionBar>
            </Main>
            <CloseButton>
                <box-icon name='x' size="lg" onClick={closeForm} />
            </CloseButton>
        </OnboardingContainer>
    );
}

export default FocusedForm;