import React from 'react';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import Container from '@components/Container';
import Button from '@components/Button';
import { useTheme } from '@emotion/react';
import RES from '@utils/resources';
import { state } from '../globalState';
import { useSnapshot } from 'valtio';

const HeadlineText = styled.h1(({ theme }) => ({
    ...theme.typography.h2,
    margin: 0,
    maxWidth: '40ch',
    textAlign: 'center'
}));

const HeadlineSubText = styled.h1(({ theme }) => ({
    ...theme.typography.subtitle,
    margin: '3rem 0 0',
    maxWidth: '40ch',
    color: theme.colors.primary.main
}));

const Headline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    color: theme.colors.tertiary,
    textAlign: 'center'
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '70ch',
    color: theme.colors.tertiary,
    textAlign: 'center'
}));

const LeaderName = styled.h4(({ theme }) => ({
    ...theme.typography.h5,
    fontWeight: '700',
    textAlign: 'center'
}));

const LeaderJobDescription = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    textAlign: 'center',
    fontWeight: '400',
    letterSpacing: '0.04em'
}));

const LeaderImg = styled.img(() => ({
    width: '160px'
}));

const JoinOurCrew = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.colors.tertiary,
    textAlign: 'center'
}));

const JoinUsMessage = styled.h6(({ theme }) => ({
    ...theme.typography.h6,
    fontWeight: '800',
    color: theme.colors.common.background
}));

const JobPostsHeader = styled.div(({ theme }) => ({
    ...theme.typography.h5,
    fontWeight: '900',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.grey.intensity800}`,
    paddingBottom: '1rem',
    '& > div': {
        minWidth: '30%'
    },
    '& > div:first-child': {
        minWidth: '40%'
    },
    [ theme.mediaQuery.mobileOnly ]: {
        display: 'none'
    }
}));

const JobPost = styled.div(({ theme }) => ({
    ...theme.typography.p,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    borderBottom: `1px solid ${theme.colors.grey.intensity800}`,
    paddingBottom: '1rem',
    '& > div': {
        minWidth: '30%'
    },
    '& a': {
        color: theme.colors.common.foreground
    },
    '& > div:first-child': {
        minWidth: '40%'
    },
    [ theme.mediaQuery.mobileOnly ]: {
        flexDirection: 'column',
        '& > div:first-child': {
            fontWeight: '900'
        },
        '& > div:nth-child(2)': {
            fontStyle: 'italic'
        }
    }
}));

function sendEmail() {
    window.location.href = 'mailto:careers@facings.io';
}

function About() {
    const theme = useTheme();
    const snap = useSnapshot(state);

    const joinStyle = {
        backgroundColor: theme.colors.primary.main,
        padding: theme.spacing.m,
        gap: theme.spacing.m,
        maxWidth: 'fit-content',
        [theme.mediaQuery.tabletUp]: {
            width: '80%',
            flexDirection: 'row'
        },
        [theme.mediaQuery.mobileOnly]: {
            textAlign: 'center'
        },
        button: {
            whiteSpace: 'nowrap',
            backgroundColor: theme.colors.common.background,
            color: theme.colors.common.foreground,
            '&:hover': {
                backgroundColor: theme.colors.common.foreground,
                color: theme.colors.common.background
            }
        }
    };

    return (
        <>
            <Container
                direction={"column"}
                alignItems={'center'}
                gap={`${theme.spacing.xs}`}
                padding={`0 ${theme.spacing.xs}`}
                isFullWidth={false}
            >
                <HeadlineSubText>{STRINGS.about.missionTitle}</HeadlineSubText>
                <HeadlineText>{STRINGS.about.mission}</HeadlineText>
            </Container>
            <Container direction="column" alignItems="center" gap={`${theme.spacing.xxl}`} margin={`${theme.spacing.xxl} 0`} >
                <Container
                    direction="column"
                    alignItems={'center'}
                    gap={`${theme.spacing.xs}`}
                    padding={`0 ${theme.spacing.xs}`}
                    isFullWidth={false}
                >
                    <Headline>{STRINGS.about.leadership}</Headline>
                    <Subtext>{STRINGS.about.leadershipDescription}</Subtext>
                </Container>
                <Container
                    direction={'row'}
                    alignItems={'baseline'}
                    gap={`${theme.spacing.m}`}
                    padding={`0 ${theme.spacing.xxl}`}
                    isFullWidth={false}
                >
                    {
                        RES.leadership.map((leader, key) => (
                            <Container
                                key={key}
                                direction={'column'}
                                gap={`${theme.spacing.xxs}`}
                                alignItems={'center'}
                            >
                                {/* <LeaderImg src={leader.srcImg} alt={leader.name} /> */}
                                <LeaderName>{leader.name}</LeaderName>
                                <LeaderJobDescription>{leader.jobDescription}</LeaderJobDescription>
                            </Container>
                        ))
                    }
                </Container>
                <Container
                    direction={"column"}
                    alignItems={'center'}
                    gap={`${theme.spacing.xs}`}
                    padding={`0 ${theme.spacing.xs}`}
                    isFullWidth={false}
                >
                    <JoinOurCrew>Our Team</JoinOurCrew>
                    <Subtext>{STRINGS.about.joinDescription}</Subtext>
                </Container>
                <Container
                    alignItems={'baseline'}
                    gap={`${theme.spacing.m}`}
                    padding={`0 ${theme.spacing.xs}`}
                    isFullWidth={false}
                    flexWrap={'wrap'}
                >
                    {
                        RES.team.map((leader, key) => (
                            <Container
                                key={key}
                                direction={'column'}
                                gap={`${theme.spacing.xxs}`}
                                alignItems={'center'}
                                style={{
                                    width: '220px',
                                    margin: 'auto'
                                }}
                            >
                                <LeaderName>{leader.name}</LeaderName>
                                <LeaderJobDescription>{leader.jobDescription}</LeaderJobDescription>
                            </Container>
                        ))
                    }
                </Container>
                <Container
                    direction={"column"}
                    alignItems={'center'}
                    gap={`${theme.spacing.xs}`}
                    padding={`0 ${theme.spacing.xs}`}
                    isFullWidth={false}
                >
                    <JoinOurCrew>{STRINGS.about.join}</JoinOurCrew>
                </Container>
                <Container
                    direction={"column"}
                    alignItems={'center'}
                    gap={`${theme.spacing.xs}`}
                    padding={`0 ${theme.spacing.xs}`}
                    isFullWidth={false}
                >
                    <JobPostsHeader>
                        <div>Position</div>
                        <div>Type</div>
                        <div>Link</div>
                    </JobPostsHeader>
                    <JobPost>
                        <div>Create your own position</div>
                        <div>Any type</div>
                        <div><a href="mailto:careers@facings.io" target="_blank" rel="noreferrer">Send us your CV/Resume</a></div>
                    </JobPost>
                </Container>
                {/* <Container
                    direction={'row'}
                    style={joinStyle}
                >
                    <JoinUsMessage>{STRINGS.about.joinPassionate}</JoinUsMessage>
                    <Button onClick={() => state.onboarding = true}>{STRINGS.about.joinButton}</Button>
                </Container> */}
            </Container>
        </>
    );
}

export default About;
