import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import StyledModal from '@components/Modal';

const Help = styled.div(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.s,
    color: theme.colors.intensity400,
    'a': {
        color: theme.colors.primary.main
    },
    'a:hover': {
        color: theme.colors.primary.dark,
        transition: theme.transition.default
    }
}));

function FormModal({ show, onConfirm, onClose, title, style, help, ...otherProps }) {
    const theme = useTheme();

    const IframeStyle = {
        textAlign: 'center',
        height: '90%',
        position: 'relative',
        maxHeight: '100vh !important',
        overflowY: 'scroll !important',
        width: '100%'
    };

    const sibFormStyle = {
        textAlign: 'center',
        height: '90%',
        position: 'relative',
        maxHeight: '100vh !important',
        overflowY: 'scroll !important',
        width: '100%',
        maxWidth: '720px'
    };

    return (<>
        <StyledModal show={show} onClose={onClose} style={sibFormStyle}>
            <iframe
                width="540"
                height="680"
                src="https://8b8b79e2.sibforms.com/serve/MUIEACzgJSKgq4PLCAyQXPZq-nLZXcdgMWxLSBgKxb4pNDSZY173NluVfTC9KBzK4acnqI0DX57JbAe2uGhGKV5VKsqriHcZoBXPBdc8e4vPVob0VaMd30Jr30yAZPECmWoa1Wge1wS-liwPz9D4g_J6PFcKlnj9RYtnKqWtZV2bloKD-P-Ade8e_4VdhVPTI7T3jtgrpUpXlbPn"
                frameBorder="0"
                scrolling="auto"
                allowFullScreen
                style={IframeStyle}
            />
            { help ? <Help>{help}</Help> : null }
        </StyledModal>
    </>);
}

export default FormModal;
