import STRINGS from '@utils/strings';
import styled from '@emotion/styled';
import RES from '@utils/resources';
import StyledButton from '@components/Button';
import { isReleased } from '@utils/globals';
import { useTheme } from '@emotion/react';

const ContentContainer =  styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.tertiary,
    textAlign: 'left',
    gap: theme.spacing.xxs,
    [ theme.mediaQuery.largeMobileOnly ]: {
        alignItems: 'flex-start',
        margin: `0 ${theme.spacing.s} ${theme.spacing.m}`,
        gap: theme.spacing.xxs
    }
}));

const Title =  styled.h3(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.colors.secondary,
    fontWeight: 800,
    textTransform: 'capitalize'
}));

const Subtitle =  styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    fontSize: '1rem',
    fontWeight: 600
}));

const Description =  styled.p(() => ({
    lineHeight: '180%',
    fontSize: '1rem',
    fontWeight: 300,
    margin: '1rem 1rem 1rem 0'
}));

const Image = styled.img(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    margin: '1rem',
    // boxShadow: '-1rem 1rem 0px #2E33C2',
    [ theme.mediaQuery.largeMobileUp ]: {
        width: 260,
        margin: '2rem'
    }
}));

const Video = styled.video(({ theme }) => ({
    width: 260,
    objectFit: 'contain',
    margin: '1rem',
    [ theme.mediaQuery.largeMobileUp ]: {
        margin: '2rem'
    }
}));

const ButtonStyle = {
    width: 'fit-content',
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
    '&:disabled': {
        opacity: '75%',
        cursor: 'not-allowed'
    }
};

const SpotlightStyle =  styled.div(({ theme, invert }) => ({
    display: 'flex',
    flexDirection: invert ? 'row-reverse' : 'row',
    color: theme.colors.primary,
    maxWidth: '65rem',
    gap: theme.spacing.xl,
    alignItems: 'center',
    [ theme.mediaQuery.largeMobileOnly ]: {
        flexDirection: 'column',
        padding: `0 ${theme.spacing.xs}`,
        gap: theme.spacing.xxs
    }
}));

const InfoContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xxs
}));

const InfoItem = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.m,
    width: '100%',
    textAlign: 'left',
    [ theme.mediaQuery.mobileOnly ]: {
        flexDirection: 'column',
        gap: theme.spacing.xxxs
    }
}));

const InfoTitle = styled.p(() => ({
    minWidth: '110px',
    fontWeight: '400',
    whiteSpace: 'nowrap'
}));

const InfoDescription = styled.p(() => ({
    textAlign: 'left'
}));

const ContainerLink = styled.a(() => ({
    textDecoration: 'none',
    color: 'inherit'
}));

function handleInfo(info) {
    return (
        <InfoContainer>
            <InfoItem>
                <InfoTitle>{STRINGS.collections.collectionName}</InfoTitle>
                <InfoDescription>{info.name}</InfoDescription>
            </InfoItem>
            <InfoItem>
                <InfoTitle>{STRINGS.collections.packsAvailable}</InfoTitle>
                <InfoDescription>{info.available}</InfoDescription>
            </InfoItem>
        </InfoContainer>
    );
}

function Spotlight({ invert, imgSrc, type, title, subtitle, description, button, buttonCallback, info, url, buttonDisabled=false }) {
    return (
        <ContainerLink href={url} target="_blank">
            <SpotlightStyle invert={invert}>
                { imgSrc && type === 'video' ?
                    <Video muted autoPlay loop playsInline >
                        <source src={imgSrc} type="video/mp4" />
                    </Video> : <Image src={imgSrc} alt="image" /> }
                <ContentContainer>
                    { title ? <Title>{title}</Title> : null }
                    { subtitle ? <Subtitle>{subtitle}</Subtitle> : null }
                    { description ? <Description dangerouslySetInnerHTML={{ __html: description }} /> : null }
                    { button ? <StyledButton disabled={buttonDisabled} style={ButtonStyle} onClick={buttonCallback}>{button}</StyledButton> : null }
                    { info ? handleInfo(info) : null }
                </ContentContainer>
            </SpotlightStyle>
        </ContainerLink>
    );
}

export default Spotlight;