import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Input from '@components/Input';
import Textarea from '@components/Textarea';
import Checkbox from '@components/Checkbox';
import { validateEmail } from './formValidations';

const Question = styled.h2(({ theme }) => ({
    ...theme.typography.h2,
    fontWeight: '900',
    letterSpacing: '0.02em',
    marginBottom: '1rem',
    textAlign: 'center',
    [theme.mediaQuery.tabletLandscapeBelow]: {
        fontSize: '1.75rem'
    }
}));

const RadioButton = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'block',
    width: '170px',
    height: '170px',
    margin: '2rem 0.5rem 0',
    '&:hover': {
        borderColor: theme.colors.common.foreground
    },
    '& input[type="radio"]': {
        position: 'absolute',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%'
    },
    '& > div': {
        ...theme.typography.h5,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        fontWeight: '700',
        pointerEvents: 'none',
        borderRadius: '1rem',
        border: `0.25rem solid ${theme.colors.grey.intensity800}`
    },
    '&:hover > div': {
        borderColor: theme.colors.common.foreground
    },
    '&:hover box-icon': {
        fill: theme.colors.primary.main
    },
    '& box-icon': {
        fill: theme.colors.grey.intensity800,
        width: '63px',
        height: '63px',
        margin: '1rem auto'
    },
    '& input:checked ~ div': {
        backgroundColor: theme.colors.common.foreground,
        color: theme.colors.common.background,
        borderColor: theme.colors.common.foreground,
        borderRadius: '1rem'
    },
    '& input:checked ~ box-icon': {
        fill: `${theme.colors.common.background}!important`
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        width: '300px',
        height: '80px',
        margin: '0.25rem',
        '& > div': {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        '& box-icon': {
            fill: theme.colors.grey.intensity800,
            width: '63px',
            height: '63px',
            margin: '1rem 1.5rem'
        }
    }
}));

const FormContainer = styled.div(({ theme }) => ({
    position: 'relative',
    '&.buttons': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    [theme.mediaQuery.tabletLandscapeBelow]: {
        '&.buttons': {
            flexDirection: 'column'
        },
        '& > div': {

        }
    }
}));

const Subtext = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.foreground,
    width: '550px',
    maxWidth: '100%',
    textAlign: 'center',
    margin: 'auto'
}));

function FormData(formValues, setFormValues)  {

    const handleChange = (element) => {
        const id = element.target.id,
            value = element.target.value,
            newValues = { ...formValues };

        newValues[id] = value;
        setFormValues(newValues);
    };

    const getValue = (id, value) => {
        const newValues = { ...formValues };
        if (id === 'email') {
            if (validateEmail(value)) {
                newValues[id] = value;
            }
        } else {
            newValues[id] = value;
        }
        setFormValues(newValues);
    };

    return [
        {
            type: 'General',
            required: ['email'],
            jsx: <div key={'contact'}>
                <Question>What’s your contact info?</Question>
                <FormContainer>
                    <Input initialValue={formValues.email} id="email" label="Email" type="email" getState={getValue} required />
                    <Input initialValue={formValues.phone} id="phone" label="Phone number" type="tel" getState={getValue} />
                </FormContainer>
            </div>

        },
        {
            type: 'General',
            required: [],
            jsx: <div key={'name'}>
                <Question>What’s your name?</Question>
                <FormContainer>
                    <Input initialValue={formValues.firstname} id="firstname" label="First name" type="text" getState={getValue} />
                    <Input initialValue={formValues.lastname} id="lastname" label="Last name" type="text" getState={getValue} />
                </FormContainer>
            </div>
        },
        {
            type: 'General',
            required: [],
            jsx: <div key={'describe-yourself'}>
                <Question>
                    { formValues.firstname ? `Hey ${formValues.firstname}, how would you describe yourself?` : 'How would you describe yourself?' }
                </Question>
                <FormContainer className="buttons">
                    <RadioButton>
                        <input
                            checked={formValues.identity === 'brand'}
                            id="identity" type="radio" name="identity" value="brand"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon type='solid' name='purchase-tag' />
                            <span>Brand</span>
                        </div>
                    </RadioButton>
                    <RadioButton>
                        <input
                            checked={formValues.identity === 'artist'}
                            id="identity" type="radio" name="identity" value="artist"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon type='solid' name='paint' />
                            <span>Artist</span>
                        </div>
                    </RadioButton>
                </FormContainer>
            </div>
        },
        {
            type: 'General',
            required: [],
            jsx: <div key={'company'}>
                <Question>
                    { formValues.firstname ? `Okay ${formValues.firstname}, are you representing an organization?` : 'Are you representing a company or organization?' }
                </Question>
                <FormContainer>
                    <Input initialValue={formValues.company} id="company" label="Company/Affiliation" type="text" getState={getValue} disabled={formValues.individual} />
                    <Input initialValue={formValues.jobtitle} id="jobtitle" label="Job title" type="text" getState={getValue} disabled={formValues.individual} />
                    <Checkbox initialValue={formValues.individual} id="individual" label="I‘m applying as an individual" getState={getValue} />
                </FormContainer>
            </div>
        },
        {
            type: 'Project Info',
            required: [],
            jsx: <div key={'project-maturity'}>
                <Question>How mature is your project thus far?</Question>
                <FormContainer className="buttons">
                    <RadioButton>
                        <input
                            checked={formValues.maturity === 'brainstorming'}
                            id="maturity" type="radio" name="maturity" value="brainstorming"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon name='brain' type='solid'  />
                            <span>Brainstorming</span>
                        </div>
                    </RadioButton>
                    <RadioButton>
                        <input
                            checked={formValues.maturity === 'strategy'}
                            id="maturity" type="radio" name="maturity" value="strategy"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon name='planet' type='solid'  />
                            <span>Strategy</span>
                        </div>
                    </RadioButton>
                    <RadioButton>
                        <input
                            checked={formValues.maturity === 'production'}
                            id="maturity" type="radio" name="maturity" value="production"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon name='paint-roll' type='solid'  />
                            <span>Production</span>
                        </div>
                    </RadioButton>
                    <RadioButton>
                        <input
                            checked={formValues.maturity === 'launch ready'}
                            id="maturity" type="radio" name="maturity" value="launch ready"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon name='rocket' type='solid'  />
                            <span>Launch Ready</span>
                        </div>
                    </RadioButton>
                </FormContainer>
            </div>
        },
        {
            type: 'Project Info',
            required: [],
            jsx: <div key={'new_brand'}>
                <Question>Does your project have a community following?</Question>
                <Subtext>
                            Answer with yes if you have a community following, customers, and brand awareness.
                            Or no means you have little brand awareness and a small following.
                </Subtext>
                <FormContainer className="buttons">
                    <RadioButton>
                        <input
                            checked={formValues.new_brand === 'Yes'}
                            id="new_brand" type="radio" name="new_brand" value="Yes"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon type='solid' name='group' />
                            <span>Yes</span>
                        </div>
                    </RadioButton>
                    <RadioButton>
                        <input
                            checked={formValues.new_brand === 'No'}
                            id="new_brand" type="radio" name="new_brand" value="No"
                            onChange={(e) => handleChange(e)}
                        />
                        <div>
                            <box-icon type='solid' name='megaphone' />
                            <span>No</span>
                        </div>
                    </RadioButton>
                </FormContainer>
            </div>
        },
        {
            type: 'NFT Experience',
            required: [],
            jsx: <div key={'collection'}>
                <Question>Please describe the collection(s) you hope to launch</Question>
                <FormContainer>
                    <Textarea initialValue={formValues.collection_description} id="collection_description" label="Collection overview" getState={getValue} />
                </FormContainer>
            </div>
        },
        {
            type: 'NFT Experience',
            required: [],
            jsx: <div key={'sales-strategy'}>
                <Question>Please provide an overview of your sales strategy</Question>
                <Subtext>…for your collection. This can include social media publicity, podcast episodes, advertising, etc. If applicable, include social media handles and advertising partners.</Subtext>
                <FormContainer>
                    <Textarea initialValue={formValues.strategy} id="strategy" label="Sales strategy" getState={getValue} />
                </FormContainer>
            </div>
        },
        {
            type: 'NFT Experience',
            required: [],
            jsx: <div key={'collateral'}>
                <Question>Provide links to existing collateral for your brand</Question>
                <Subtext>Including but not limited to websites, social media accounts, press releases, etc.</Subtext>
                <FormContainer>
                    <Textarea initialValue={formValues.collateral} id="collateral" label="Links to check out" getState={getValue} />
                </FormContainer>
            </div>
        }
    ];
}

export default FormData;